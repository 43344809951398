body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, "Montserrat", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5rem;
}
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

code {
  font-family: source-code-pro, "Montserrat", monospace;
}
html {
  font-size: 1.25rem;
}

p {
  font-size: 0.75rem;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  img[src$=".svg"] {
    width: 100%;
  }
  img[src$=".png"] {
    width: 100%;
  }
}
@media print {
  html,
  body {
    height: 170% !important;
    overflow: initial !important;
  }
  @page {
    size: landscape;
  }
}

